import React from "react";

export const Logo = () => {
    return (

        <svg style={{ width: "auto", height: "100%", padding: "10%" }} viewBox="0 0 501 462" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <filter x="-3.8%" y="-3.3%" width="107.7%" height="108.4%" filterUnits="objectBoundingBox" id="filter-2">
                    <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur stdDeviation="5.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"/>
                </filter>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="logo" transform="translate(-6.000000, -29.000000)" fillRule="nonzero">
                    <g id="Group" transform="translate(16.000000, 36.000000)">
                        <path d="M285.084112,179.56 C293.495369,182.220013 300.084088,186.664969 304.850467,192.895 C309.616846,199.125031 312,206.789954 312,215.89 C312,228.770064 306.988368,238.674965 296.964953,245.605 C286.941539,252.535035 272.327199,256 253.121495,256 L177,256 L177,109 L248.915888,109 C266.859903,109 280.63313,112.429966 290.235981,119.29 C299.838833,126.150034 304.640187,135.459941 304.640187,147.22 C304.640187,154.360036 302.922914,160.729972 299.488318,166.33 C296.053721,171.930028 291.252367,176.339984 285.084112,179.56 Z M210.85514,134.62 L210.85514,169.27 L244.71028,169.27 C253.121537,169.27 259.499978,167.800015 263.845794,164.86 C268.191611,161.919985 270.364486,157.580029 270.364486,151.84 C270.364486,146.099971 268.191611,141.795014 263.845794,138.925 C259.499978,136.054986 253.121537,134.62 244.71028,134.62 L210.85514,134.62 Z M250.598131,230.38 C259.570138,230.38 266.334089,228.910015 270.890187,225.97 C275.446284,223.029985 277.724299,218.480031 277.724299,212.32 C277.724299,200.139939 268.682333,194.05 250.598131,194.05 L210.85514,194.05 L210.85514,230.38 L250.598131,230.38 Z" id="B" fill="#FFFFFF"/>
                        <g id="V">
                            <polygon points="481 0 290.486275 440 189.884967 440 0 0 110.03268 0 243.95817 314.285714 379.769935 0"
                                     fill="black" fillOpacity="1" filter="url(#filter-2)" />
                            <polygon points="481 0 290.486275 440 189.884967 440 0 0 110.03268 0 243.95817 314.285714 379.769935 0"
                                     fill="#FFFFFF"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
};

export const B = () => {
    return (
        <svg style={{ width: "auto", height: "100%", padding: "10%" }} viewBox="0 0 135 147" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient x1="-100.630868%" y1="-74.957728%" x2="203.089403%" y2="248.102307%" id="linearGradient-1">
                    <stop stopColor="#4B79A1" offset="0%"/>
                    <stop stopColor="#283E51" offset="100%"/>
                </linearGradient>
                <linearGradient x1="20.5136588%" y1="13.7844125%" x2="78.8841673%" y2="84.9456991%" id="linearGradient-2">
                    <stop stopColor="#4E54C8" offset="0%"/>
                    <stop stopColor="#8F94FB" offset="100%"/>
                </linearGradient>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="logo" transform="translate(-193.000000, -145.000000)" fillRule="nonzero">
                    <g id="Group" transform="translate(16.000000, 36.000000)">
                        <g id="B">
                            <path d="M285.084112,179.56 C293.495369,182.220013 300.084088,186.664969 304.850467,192.895 C309.616846,199.125031 312,206.789954 312,215.89 C312,228.770064 306.988368,238.674965 296.964953,245.605 C286.941539,252.535035 272.327199,256 253.121495,256 L177,256 L177,109 L248.915888,109 C266.859903,109 280.63313,112.429966 290.235981,119.29 C299.838833,126.150034 304.640187,135.459941 304.640187,147.22 C304.640187,154.360036 302.922914,160.729972 299.488318,166.33 C296.053721,171.930028 291.252367,176.339984 285.084112,179.56 Z M210.85514,134.62 L210.85514,169.27 L244.71028,169.27 C253.121537,169.27 259.499978,167.800015 263.845794,164.86 C268.191611,161.919985 270.364486,157.580029 270.364486,151.84 C270.364486,146.099971 268.191611,141.795014 263.845794,138.925 C259.499978,136.054986 253.121537,134.62 244.71028,134.62 L210.85514,134.62 Z M250.598131,230.38 C259.570138,230.38 266.334089,228.910015 270.890187,225.97 C275.446284,223.029985 277.724299,218.480031 277.724299,212.32 C277.724299,200.139939 268.682333,194.05 250.598131,194.05 L210.85514,194.05 L210.85514,230.38 L250.598131,230.38 Z"
                                  fill="url(#linearGradient-1)"/>
                            <use fill="url(#linearGradient-1)"/>
                            <use fill="url(#linearGradient-2)"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const V = () => {
    return (
        <svg style={{ width: "auto", height: "100%", padding: "10%" }}  viewBox="0 0 501 462" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient x1="0%" y1="8.16062344%" x2="99.752256%" y2="91.7167173%" id="linearGradient-1">
                    <stop stopColor="#4B79A1" offset="0%"/>
                    <stop stopColor="#283E51" offset="100%"/>
                </linearGradient>
                <linearGradient x1="15.0386641%" y1="19.6952479%" x2="84.2473509%" y2="79.2421253%" id="linearGradient-2">
                    <stop stopColor="#4E54C8" offset="0%"/>
                    <stop stopColor="#8F94FB" offset="100%"/>
                </linearGradient>
                <polygon id="path-3" points="481 0 290.486275 440 189.884967 440 0 0 110.03268 0 243.95817 314.285714 379.769935 0"/>
                <filter x="-3.8%" y="-3.3%" width="107.7%" height="108.4%" filterUnits="objectBoundingBox" id="filter-4">
                    <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur stdDeviation="5.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"/>
                </filter>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="logo" transform="translate(-6.000000, -29.000000)" fillRule="nonzero">
                    <g id="Group" transform="translate(16.000000, 36.000000)">
                        <g id="V">
                            <polygon points="481 0 290.486275 440 189.884967 440 0 0 110.03268 0 243.95817 314.285714 379.769935 0"
                                     fill="black" fillOpacity="1" filter="url(#filter-4)"/>
                            <polygon points="481 0 290.486275 440 189.884967 440 0 0 110.03268 0 243.95817 314.285714 379.769935 0"
                                     fill="url(#linearGradient-1)"/>
                            <polygon points="481 0 290.486275 440 189.884967 440 0 0 110.03268 0 243.95817 314.285714 379.769935 0"
                                     fill="url(#linearGradient-2)"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};