import React from "react";

// reactstrap components
import {Button, Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";

import { Logo } from "../SvgIcons/Brand"

class PageHeader extends React.Component {
  render() {
    return (
      <div className="page-header header-filter">
          <div className="squares square1"><Logo/></div>
          <div className="squares square2"><Logo/></div>
          <div className="squares square3"><Logo/></div>
          <div className="squares square4"><Logo/></div>
          <div className="squares square5"><Logo/></div>
          <div className="squares square6"><Logo/></div>
          <div className="squares square7"><Logo/></div>
        <Container>
            <div className="content-center">
                <Row className="row-grid justify-content-between align-items-center text-left">
                    <Col lg="5" md="6">
                        <h1 className="text-white" style={{ textAlign: "center" }}>
                            •Bluvio• <br/> Software Studio
                        </h1>
                        <p className="text-white mb-3" style={{ fontSize: "16pt", textAlign: "center" }}>
                            Essential modern technology that really helps your business!
                        </p>
                        <div className="btn-wrapper mb-3">
                            <Link to="/contact" style={{ textAlign: "center", display: "block" }}>
                                <Button color="primary">
                                    Just contact us
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="7" md="5">
                        <img style={{ width: "100%", height: "100%" }}
                            alt="..."
                            className="img-fluid"
                            src={require("assets/icons/preview.svg")}
                        />
                    </Col>
                </Row>
            </div>
        </Container>
      </div>
    );
  }
}

export default PageHeader;
