import React, { useEffect } from "react";
import Link from "react-router-dom/Link";
import BackendService from "services/backend";
import Swal from "sweetalert2";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import PageHeader from "components/PageHeader/PageHeader.jsx";
import Footer from "components/Footer/Footer.jsx";
import "./Contacts.css"
import FormUtils from "../services/utils";

const source = [
    {
        "title": "Phone Number",
        "text": "+38(097) 651-46-87",
        "href": "tel:+38(097) 651-46-87",

        "icon": "mobile",
        "color": "warning",
    },
    {
        "title": "Email address",
        "text": "hasdfa@bluvio.studio",
        "href": "mailto:hasdfa@bluvio.studio",

        "icon": "email-85",
        "color": "warning",
    },
    {
        "title": "Our location",
        "text": "Ukraine Kyiv / Dnipro",
        "href": "https://www.google.com/maps/place/Ukraine",

        "icon": "square-pin",
        "color": "primary",
    },
];

const Index = () => {
    useEffect(() => {
        document.body.classList.toggle("index-page");
        window.scrollTo(0, 0);
        return () =>  {
            document.body.classList.toggle("index-page");
        }
    }, []);

    const handleFormSubmition = event => {
        event.preventDefault();
        const data = FormUtils.parseFormFromInput(event);
        console.log(data);

        Swal.fire({
            title: 'Sending...',
            showCancelButton: false,
            showConfirmButton: false,
            showLoaderOnConfirm: true,
            onOpen: () => {
                return BackendService.sendMessage(data)
                    .then((data) => {
                        Swal.fire({
                            title: "Sent successfully!",
                            text: "In the near future we will contact you!",
                            icon: 'success',
                        })
                    })
                    .catch((err) => {
                        Swal.fire({
                            title: "Could not send you`re message!",
                            text: err,
                            icon: 'error',
                        })
                    })
            },
            allowOutsideClick: () => false,
        });
    };

    return (
        <>
            <IndexNavbar collapseAt={435}/>
            <div className="wrapper">
                <section className="contacts header-filter">
                    <div className="page-header-image" style={{ width: "100%",
                        backgroundImage: `url(${require("assets/img/andre-benz.jpg")})`,
                    }}/>
                    <h1 className="contact-title">Got a question?</h1>
                    <h3 className="contact-subtitle">We'd like to talk more about what you need</h3>
                </section>
                <div className="main contacts-content">
                    <div className="container-fluid">
                        <div className="infos mb-5 row">
                            {
                                source.map((item, key) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6" key={key}>
                                        <a href={ item.href } target="_blank" rel="noopener noreferer">
                                            <div className="info info-hover">
                                                <div className={ "icon icon-" + item.color } style={{ display: "inline-block" }}>
                                                    <i className={ "tim-icons icon-" + item.icon }/>
                                                </div>
                                                <h4 className="info-title">{ item.title }</h4>
                                                <p className="description">{ item.text }</p>
                                            </div>
                                        </a>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="mt-5 mb-4 pt-5 row">
                            <div className="ml-auto mr-auto text-center mt-5 col-md-8"><span
                                className="badge badge-info">Leave a message</span><h1 className="title">Tell us more
                                about <b>yourself</b></h1><h4 className="desc">Whether you have questions or you would
                                just like to say hello, contact us.</h4></div>
                        </div>
                        <div className="row">
                            <div className="mx-auto col-md-10">
                                <form id="contact-form" method="post" role="form" className="p-3"
                                    onSubmit={handleFormSubmition}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6"><label>First name</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <i className="tim-icons icon-single-02"/>
                                                        </span>
                                                    </div>
                                                    <input name="firstName" aria-label="First Name..." placeholder="First Name..."
                                                           type="text" className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group"><label>Last name</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <i className="tim-icons icon-single-02"/>
                                                        </span>
                                                        </div>
                                                        <input name="secondName" aria-label="Last Name..." placeholder="Last Name..."
                                                               type="text" className="form-control"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group"><label>Email address</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="tim-icons icon-email-85"/>
                                                    </span>
                                                </div>
                                                <input name="email" placeholder="Email Here..." type="email" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="form-group"><label>Your message</label>
                                            <textarea id="message" name="message" rows="6"
                                                      className="form-control" />
                                        </div>
                                        <div className="row">
                                            <div className="ml-auto col-md-6">
                                                <button className="btn-round pull-right btn btn-primary" type="submit">
                                                    Send Message
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Index;