import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

class ComponentsNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      buttonColor: "primary",
      color: "navbar-transparent"
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.changeColor);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeColor);
  }
  changeColor = () => {
    let { collapseAt } = this.props;
    if (!collapseAt) collapseAt = 100;

    if (
      document.documentElement.scrollTop > collapseAt - 1 ||
      document.body.scrollTop > collapseAt - 1
    ) {
      this.setState({
        buttonColor: "info",
        color: "bg-bluvio"
      });
    } else if (
      document.documentElement.scrollTop < collapseAt ||
      document.body.scrollTop < collapseAt
    ) {
      this.setState({
        buttonColor: "primary",
        color: "navbar-transparent"
      });
    }
  };
  toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  onCollapseExiting = () => {
    this.setState({
      collapseOut: "collapsing-out"
    });
  };
  onCollapseExited = () => {
    this.setState({
      collapseOut: ""
    });
  };
  render() {
    return (
      <Navbar
        className={"fixed-top " + this.state.color}
        color-on-scroll="100"
        expand="lg"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              style={{ fontSize: "100%" }}
              data-placement="bottom" to="/"
              rel="noopener noreferrer"
              title="Bluvio Software Studio"
              tag={Link}
            >
              <span>•Bluvio• </span>
              Software Studio
            </NavbarBrand>
            <button
              aria-expanded={this.state.collapseOpen}
              className="navbar-toggler navbar-toggler"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className={"justify-content-end " + this.state.collapseOut}
            navbar
            isOpen={this.state.collapseOpen}
            onExiting={this.onCollapseExiting}
            onExited={this.onCollapseExited}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <a href="/" onClick={e => e.preventDefault()}>
                    •Bluvio• Software
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button aria-expanded={this.state.collapseOpen}
                    className="navbar-toggler"
                    onClick={this.toggleCollapse}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav navbar>
              {/*<NavItem className="p-0">*/}
              {/*  <NavLink*/}
              {/*    data-placement="bottom"*/}
              {/*    href="https://twitter.com/CreativeTim"*/}
              {/*    rel="noopener noreferrer"*/}
              {/*    target="_blank"*/}
              {/*    title="Follow us on Twitter"*/}
              {/*  >*/}
              {/*    <i className="fab fa-twitter" />*/}
              {/*    <p className="d-lg-none d-xl-none">Twitter</p>*/}
              {/*  </NavLink>*/}
              {/*</NavItem>*/}
              {/*<NavItem className="p-0">*/}
              {/*  <NavLink*/}
              {/*    data-placement="bottom"*/}
              {/*    href="https://www.facebook.com/CreativeTim"*/}
              {/*    rel="noopener noreferrer"*/}
              {/*    target="_blank"*/}
              {/*    title="Like us on Facebook"*/}
              {/*  >*/}
              {/*    <i className="fab fa-facebook-square" />*/}
              {/*    <p className="d-lg-none d-xl-none">Facebook</p>*/}
              {/*  </NavLink>*/}
              {/*</NavItem>*/}
              <NavItem className="p-0">
                <Link
                  className="d-lg-none d-xl-none d-display"
                  data-placement="bottom" to="/contact"
                  title="Contact us" onClick={this.toggleCollapse}
                >
                  <i className="tim-icons icon-alert-circle-exc" />
                  Contact us
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/contact">
                  <Button
                      className="nav-link d-none d-lg-block"
                      color={ this.state.buttonColor }
                  >
                    Contact us <i className="tim-icons icon-alert-circle-exc" />
                  </Button>
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default ComponentsNavbar;
