import React, { useEffect, useState } from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";

import Index from "views/Index.jsx";
import Contacts from "views/Contact"
import LandingPage from "views/examples/LandingPage.jsx";
import RegisterPage from "views/examples/RegisterPage.jsx";
import ProfilePage from "views/examples/ProfilePage.jsx";

import "./app.scss?v=1.0.0"

const App = () => {
    const [loading, setLoading] = useState(true);
    const [currentPath, setCurrentPath] = useState(null);

    console.log(window.location.pathname);
    if (currentPath !== window.location.pathname) {
        setLoading(true);
        setCurrentPath(window.location.pathname);
    }

    useEffect(() => {
        if (!loading) return;
        setTimeout(() => {
            setLoading(false);
            window.scrollTo(0, 0);
        }, 2100);
    }, [loading]);

    return (
        <>
            { loading &&
            <div id="app-loading" style={{
                overflow: "hidden",
                zIndex: "99999999", position: "fixed",
                width: "100vw", height: "100%",
                color: "white", background: "#4e54c8",
            }}>
                <h1 style={{
                    fontSize: "32pt",
                    marginTop: "35vh",
                    textAlign: "center",

                    animationDuration: "1s",
                    animationName: "loading-opacity-anim",
                    animationIterationCount: "infinite",
                    animationDirection: "alternate",
                }}>•Bluvio• <br/> Software Studio</h1>
                <div className="container">
                    <div className="item-1"/>
                    <div className="item-2"/>
                    <div className="item-3"/>
                    <div className="item-4"/>
                    <div className="item-5"/>
                </div>
            </div>
            }
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" render={props => <Index {...props} />} />
                    <Route exact path="/contact" render={props => <Contacts {...props} />} />
                    {/*<Route*/}
                    {/*  path="/landing-page"*/}
                    {/*  render={props => <LandingPage {...props} />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/register-page"*/}
                    {/*  render={props => <RegisterPage {...props} />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/profile-page"*/}
                    {/*  render={props => <ProfilePage {...props} />}*/}
                    {/*/>*/}
                    <Route render={ () => (
                        <div style={{
                            color: "white",
                            fontSize: "32pt",
                            margin: "0 auto",
                            // textAlign: "center",
                            verticalAlign: "middle",
                            display: "inline-block",
                            marginLeft: "32px",
                            marginTop: "20%",
                        }}>
                            <b>404</b> <br/> NOT FOUND <br/>
                            <a href="/">Go to main page</a>
                        </div>
                    ) }/>
                </Switch>
            </BrowserRouter>
        </>
    );
};
export default App;