import React, { useEffect } from "react";
import "./Index.css"

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import PageHeader from "components/PageHeader/PageHeader.jsx";

import landingContent from "./landing-content.js"
import {
  Col, Container, Row,
  Card, CardBody,
} from "reactstrap";

const Index = () => {
  useEffect(() => {
    document.body.classList.toggle("index-page");
    window.scrollTo(0, 0);
    return () =>  {
      document.body.classList.toggle("index-page");
    }
  }, []);

  return (
      <>
        <IndexNavbar />
        <div className="wrapper">
          <PageHeader />
          <div className="main">
            <section className="section section-lg">
              <img alt="..." className="path" src={require("assets/img/path4.png")} />
              <img alt="..." className="path2" src={require("assets/img/path5.png")} />
              <Container style={{ "width": "100%" }}>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <h1 className="text-center">our solutions</h1>
                    <Row className="row-grid justify-content-center">
                      {
                        landingContent["our solutions"].map((item, key) => (
                            <Col lg="3" key={key}>
                              <div className="info" style={{ paddingTop: "10%" }}>
                                {/*<div className="icon icon-white">*/}
                                {/*  <i className="tim-icons icon-laptop"/>*/}
                                {/*</div>*/}
                                <img alt={item.icon} src={item.icon} style={{ width: "50%", height: "auto" }}/>
                                <h4 className="info-title">{ item.title }</h4>
                                <hr className="line-primary" />
                                <p>{ item.text }</p>
                              </div>
                            </Col>
                        ))
                      }
                    </Row>
                  </Col>
                </Row>
              </Container>
            </section>
            <div className="features-2">
              <div className="container">
                <div className="align-items-center row">
                  <div className="mr-auto text-left col-md-8 col-lg-4">
                    <h1 className="title">The best choice!</h1>
                    {/*<p className="description">*/}
                    {/*  5,000 capacity venue, holding some of the latest technology lighting*/}
                    {/*  with a 24 colour laser system Amnesia is one of the islands most legendary clubs.*/}
                    {/*</p>*/}
                    {/*<a href="/" className="btn-simple btn btn-info">Learn more*/}
                    {/*  <i className="tim-icons icon-alert-circle-exc"/>*/}
                    {/*</a>*/}
                  </div>
                  <div className="col-md-12 col-lg-8">
                    <Row className="row">
                      {
                        landingContent["main features"].map((item, key) => (
                            <Col lg="4" md="4" key={key}>
                              <div className={ "info text-center " +
                                [ "bg-danger",
                                  "bg-info info-raised",
                                  "bg-yellow"][key % 3]
                              } style={{ paddingTop: "10%" }}>
                                {/*<div className="icon icon-white"><i className="tim-icons icon-satisfied"/></div>*/}
                                <img alt={item.icon} src={item.icon} style={{
                                  width: "33%", height: "auto"
                                }}/>
                                <h4 className="info-title">{ item.title }</h4>
                                <p className="description">{ item.text }</p>
                              </div>
                            </Col>
                        ))
                      }
                      {/*<div className="col-md-4 col-lg-4">*/}
                      {/*  <div className="info text-left bg-danger info-raised">*/}
                      {/*    <div className="icon icon-white"><i className="tim-icons icon-palette"/></div>*/}
                      {/*    <h4 className="info-title">Awesome Design</h4><p className="description">Gain access to the*/}
                      {/*    demographics, psychographics, and location of unique people.</p></div>*/}
                      {/*</div>*/}
                      {/*<div className="col-md-4 col-lg-4">*/}
                      {/*  <div className="info text-left bg-default">*/}
                      {/*    <div className="icon icon-white"><i className="tim-icons icon-spaceship"/></div>*/}
                      {/*    <h4 className="info-title">Great Performance</h4><p className="description">Gain access to*/}
                      {/*    the demographics, psychographics, and location of unique people.</p></div>*/}
                      {/*</div>*/}
                    </Row>
                    {/*<div className="row">*/}
                    {/*  <div className="col-md-4 col-lg-4">*/}
                    {/*    <div className="info text-left bg-primary">*/}
                    {/*      <div className="icon icon-white"><i className="tim-icons icon-user-run"/></div>*/}
                    {/*      <h4 className="info-title">Fast Development</h4><p className="description">Gain access to*/}
                    {/*      the demographics, psychographics, and location of unique people.</p></div>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-md-4 col-lg-4">*/}
                    {/*    <div className="info text-left bg-warning info-raised">*/}
                    {/*      <div className="icon icon-white"><i className="tim-icons icon-bulb-63"/></div>*/}
                    {/*      <h4 className="info-title">Super Fresh</h4><p className="description">Gain access to the*/}
                    {/*      demographics, psychographics, and location of unique people.</p></div>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-md-4 col-lg-4">*/}
                    {/*    <div className="info text-left bg-success">*/}
                    {/*      <div className="icon icon-default"><i className="tim-icons icon-paper"/></div>*/}
                    {/*      <h4 className="info-title text-muted">Organized Content</h4><p*/}
                    {/*        className="description text-muted">Gain access to the demographics, psychographics, and*/}
                    {/*      location of unique people.</p></div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="section section-cards">
              <div className="cards">
                <Container>
                  <div className="title">
                    <h2>Our projects</h2>
                  </div>
                  <Row>
                    {
                      landingContent.portfolio.map(({ title, text, image, href, size }, key) => (
                          <>
                            <Col lg={4} md={6} sm={12}>
                              <a href={href} style={{ cursor: "pointer" }}>
                                <div data-animation="zooming" className="card-blog card-background card">
                                  <div className="full-background"
                                       style={{
                                         backgroundImage: 'url("' + image + '")',
                                         backgroundRepeat: "no-repeat",
                                         backgroundSize: "contain",
                                       }}/>
                                  <CardBody>
                                    <div className="content-bottom">
                                      <h3 className="card-title">{ title }</h3>
                                      <h6 className="card-category">{ text }</h6>
                                    </div>
                                  </CardBody>
                                </div>
                              </a>
                            </Col>
                          </>
                      ))
                    }
                  </Row>
                  <Row>
                    <Col lg={ 12 / 3 } md={ 12 / 3 } sm={0}/>
                    <Col lg={ 12 / 3 } md={ 12 / 3 } sm={12}>
                      <a href="/projects">
                        <Card style={{ padding: "16px" }}>
                          <h3 className="card-title text-center">More projects</h3>
                        </Card>
                      </a>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            {/*<div className="cd-section" id="contactus">*/}
            {/*  <div className="contactus-1 section-image"*/}
            {/*       style="background-image: url(&quot;/blk-design-system-pro-react/static/media/contact1.f1caa533.jpg&quot;);">*/}
            {/*    <div className="container">*/}
            {/*      <div className="row">*/}
            {/*        <div className="col-md-5"><h2 className="title">Get in Touch</h2><h4 className="description">You*/}
            {/*          need more information? Check what other persons are saying about our product. They are very happy*/}
            {/*          with their purchase.</h4>*/}
            {/*          <div className="info info-horizontal">*/}
            {/*            <div className="icon icon-primary"><i className="tim-icons icon-square-pin"/></div>*/}
            {/*            <div className="description"><h4 className="info-title">Find us at the office</h4>*/}
            {/*              <p className="description">Bld Mihail Kogalniceanu, nr. 8, <br/>7652 Bucharest, <br/>Romania</p>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*          <div className="info info-horizontal">*/}
            {/*            <div className="icon icon-primary">*/}
            {/*              <i className="tim-icons icon-mobile"/>*/}
            {/*            </div>*/}
            {/*            <div className="description"><h4 className="info-title">Give us a ring</h4><p*/}
            {/*                className="description">Michael Jordan <br/>+40 762 321 762 <br/>Mon - Fri, 8:00-22:00</p>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*        <div className="ml-auto mr-auto col-md-5">*/}
            {/*          <div className="card-contact card-raised card">*/}
            {/*            <form id="contact-form" method="post" role="form" className="">*/}
            {/*              <div className="text-center card-header"><h4 className="card-title">Contact Us</h4></div>*/}
            {/*              <div className="card-body">*/}
            {/*                <div className="row">*/}
            {/*                  <div className="col-md-6"><label>First name</label>*/}
            {/*                    <div className="input-group">*/}
            {/*                      <div className="input-group-prepend">*/}
            {/*                        <span className="input-group-text">*/}
            {/*                          <i className="tim-icons icon-single-02"/>*/}
            {/*                        </span>*/}
            {/*                      </div>*/}
            {/*                      <input aria-label="First Name..." placeholder="First Name..." type="text"*/}
            {/*                             className="form-control"/>*/}
            {/*                    </div>*/}
            {/*                  </div>*/}
            {/*                  <div className="pl-2 col-md-6">*/}
            {/*                    <div className="form-group"><label>Last name</label>*/}
            {/*                      <div className="input-group">*/}
            {/*                        <div className="input-group-prepend">*/}
            {/*                          <span className="input-group-text">*/}
            {/*                            <i className="tim-icons icon-caps-small"/>*/}
            {/*                          </span>*/}
            {/*                        </div>*/}
            {/*                        <input aria-label="Last Name..." placeholder="Last Name..." type="text"*/}
            {/*                               className="form-control"/>*/}
            {/*                      </div>*/}
            {/*                    </div>*/}
            {/*                  </div>*/}
            {/*                </div>*/}
            {/*                <div className="form-group"><label>Email address</label>*/}
            {/*                  <div className="input-group">*/}
            {/*                    <div className="input-group-prepend">*/}
            {/*                      <span className="input-group-text">*/}
            {/*                        <i className="tim-icons icon-email-85"/>*/}
            {/*                      </span>*/}
            {/*                    </div>*/}
            {/*                    <input placeholder="Email Here..." type="text" className="form-control"/>*/}
            {/*                  </div>*/}
            {/*                </div>*/}
            {/*                <div className="form-group">*/}
            {/*                  <label>Your message</label>*/}
            {/*                  <textarea id="message" name="message"*/}
            {/*                            rows="6" className="form-control"/>*/}
            {/*                </div>*/}
            {/*                <div className="row">*/}
            {/*                  <div className="col-md-6">*/}
            {/*                    <div className="form-check">*/}
            {/*                      <label className="form-check-label">*/}
            {/*                        <input type="checkbox"*/}
            {/*                              className="form-check-input"/>*/}
            {/*                        <span className="form-check-sign"/>I'm not a robot*/}
            {/*                      </label>*/}
            {/*                    </div>*/}
            {/*                  </div>*/}
            {/*                  <div className="col-md-6">*/}
            {/*                    <button className="btn-round pull-right btn btn-primary">Send Message</button>*/}
            {/*                  </div>*/}
            {/*                </div>*/}
            {/*              </div>*/}
            {/*            </form>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </>
  );
};
export default Index;
