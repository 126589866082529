
export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const validUrlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

export function validURL(str) {
  return !!validUrlPattern.test(str);
}

export default class FormUtils {

    static parseFormFromInput = (event) => {
        const elements = event.target.elements;
        const data = {};
        for (let i = 0; i < elements.length; i++) {
            const el = elements[i];
            if (el.name === "" || el.type === "submit") continue;
            data[el.name] = el.value;
        }
        return data;
    }

}