import React from "react";
import ReactDOM from "react-dom";
import App from "./app/app"

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss?v=1.0.0";
// import "assets/scss/blk-design-system-react.css";
import "assets/demo/demo.css";

ReactDOM.render(<App/>,
  document.getElementById("root")
);
