import dev from "../../configs/dev"
import prod from "../../configs/prod"

let config;
if (!process.env.NODE_ENV
    || process.env.NODE_ENV === 'development'
) config = dev;
else config = prod;

export const url = config.protocol + "://"
    + config.host + ":" + config.port
    + config.apiPath;