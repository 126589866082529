export default {
  "our solutions": [
    {
      title: "Multi platform Development",
      text: "Desktop / IOS / Android / Windows / Linux / MacOs",
      icon: require("assets/img/ic_platforms.svg"),
    },
    {
      title: "Web Development",
      text: "CRM / Websites / Platforms / Backend / Frontend",
      icon: require("assets/img/ic_web.svg"),
    },
    {
      title: "AI & IoT Development",
      text: "Deep Learning / Smart House / Machine Learning / Artificial Intelligence",
      icon: require("assets/img/ic_ai.svg"),
    }
  ],
  "main features": [
    {
      title: "Awesome Design",
      // text: "Gain access to the demographics, psychographics, and location of unique people.",
      icon: require("assets/img/ic_design.svg"),
    },
    {
      title: "Best Quality",
      // text: "Gain access to the demographics, psychographics, and location of unique people.",
      icon: require("assets/img/ic_quality.svg"),
    },
    {
      title: "Great Performance",
      // text: "Gain access to the demographics, psychographics, and location of unique people.",
      icon: require("assets/img/ic_fast.svg"),
    },
  ],
  portfolio: [
    {
      title: "GasBuild BHMS",
      text: "Analytics platform",
      href: "/projects/bhms-gasbuild",
      image: require("assets/projects/promo/bhms-gasbuild.svg"),
    },
    {
      title: "Profident",
      text: "Landing",
      image: require("assets/projects/promo/profident.svg"),
    },
    {
      title: "FinanC-st",
      text: "iOS open-source application",
      image: require("assets/projects/promo/financst.svg"),
    },
    {
      title: "Karazin XNU Info Panel",
      text: "Information panel with all university information",
      image: require("assets/projects/promo/xnuinfopanel.svg"),
    },
    {
      title: "JustLearn",
      text: "Testing platform for schools",
      image: require("assets/projects/promo/justlearn.svg"),
    },
    {
      title: "EShop telegram bot",
      text: "Electronics shop which works as telegram bot",
      image: require("assets/projects/promo/eshop.png"),
    },
  ]
}