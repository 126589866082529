const loremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam";
const defaults = {
    "info/carousel.php": [
        {
            "title":"carousel.modern-tech",
            "image":"https://profident-dp.pp.ua/api/v1/assets/images/slides/1.jpg"
        },
        {
            "title":"carousel.best-dentists",
            "image":"https://profident-dp.pp.ua/api/v1/assets/images/slides/2.jpg"
        },
        {
            "title":"carousel.best-materials",
            "image":"https://profident-dp.pp.ua/api/v1/assets/images/slides/3.jpg"
        },
        {
            "title":"carousel.whitening",
            "image":"https://profident-dp.pp.ua/api/v1/assets/images/slides/4.jpg"
        },
        {
            "title":"carousel.implanting",
            "image":"https://profident-dp.pp.ua/api/v1/assets/images/slides/5.jpg"
        }
    ],
    "info/contacts.php": [
        // { lat: 48.462186, long: 35.027774 }
        {
            "title":"appointment.form.map.locations.dnipro-polya-17",
            "coords": {
                "lat":48.4626,
                "long":35.0282
            },
            "phones": [
                "(063) 369-67-95",
                "(097) 189-31-16",
                "(066) 018-02-06",
                "(0562) 32-25-29"
            ],
            "workTime": {
                "from": [ "0000", "[0]", "[0]", "[0]", "[0]", "[0]", "[0]" ],
                "to": [ "2400", "[0]", "[0]", "[0]", "[0]", "[0]", "[0]" ],
            }
        },
        // { lat: 48.4887973, long: 35.0629026 }
        {
            "title":"appointment.form.map.locations.dnipro-slobojanskiy-1",
            "coords":{
                "lat":48.4888,
                "long":35.0636
            },
            "phones":[
                "(097) 189-31-17",
                "(095) 740-16-80"
            ],
            "workTime": {
                "from": [ "0000", "[0]", "[0]", "[0]", "[0]", "[0]", "[0]" ],
                "to": [ "2400", "[0]", "[0]", "[0]", "[0]", "[0]", "[0]" ],
            }
        }
    ],
    "info/partners.php": [
        { title: "partners.p1", description: loremIpsum, image: "https://profident-dp.pp.ua/api/v1/assets/images/partners/1.png" },
        { title: "partners.p2", description: loremIpsum, image: "https://profident-dp.pp.ua/api/v1/assets/images/partners/2.png" },
        { title: "partners.p3", description: loremIpsum, image: "https://profident-dp.pp.ua/api/v1/assets/images/partners/3.png" },
        { title: "partners.p4", description: loremIpsum, image: "https://profident-dp.pp.ua/api/v1/assets/images/partners/4.png" },
        { title: "partners.p5", description: loremIpsum, image: "https://profident-dp.pp.ua/api/v1/assets/images/partners/5.png" },
        { title: "partners.p6", description: loremIpsum, image: "https://profident-dp.pp.ua/api/v1/assets/images/partners/6.png" },
        { title: "partners.p7", description: loremIpsum, image: "https://profident-dp.pp.ua/api/v1/assets/images/partners/7.png" },
    ],
    "info/socialMedias.php": [
        { title: "Facebook", href: "https://facebook.com" },
        { title: "Instagram", href: "https://instagram.com" },
        { title: "LinkedIn", href: "https://linkedin.com" },
        { title: "Twitter", href: "https://twitter.com" },
    ],
};

for (const [key, value] of Object.entries(defaults)) {
    if (window.localStorage.getItem("defaults-" + key) === null) {
        window.localStorage.setItem("defaults-" + key, JSON.stringify(value));
    }
}

// const getHourAgo = () => {
//     const date = new Date();
//     return date.setHours(date.getHours() - 1);
// };

export const setUpdated = (table) => window.localStorage.setItem("updated-" + table, new Date().getTime().toString());
export const setNotUpdated = (table) => window.localStorage.setItem("updated-" + table, "0");
export const isUpdated = (table) => false; //+window.localStorage.getItem("updated-" + table) > getHourAgo();


export function defaultsFor(table) {
    const item = window.localStorage.getItem("defaults-" + table);
    if (item != null) {
        return JSON.parse(item);
    }
    return defaults[table];
}

export function saveLastData(table, data) {
    if (table && data) {
        window.localStorage.setItem("defaults-" + table, JSON.stringify(data));
        setUpdated(table);
    }
}