import { saveLastData, defaultsFor, isUpdated, setNotUpdated } from "./defaults";
import { url } from "./configs";

export const fetchBackend = (file, configs) => {
    if (configs === undefined || configs === null)
        configs = {};
    if (configs.headers === undefined || configs.headers === null)
        configs.headers = {};
    configs.headers["Origin"] = window.location.origin;

    return fetch(url + file, configs)
        .then(async (resp) => {
            try {
                resp.string = await resp.text();
                resp.data = JSON.parse(resp.string);
            } catch (e) {
                console.debug("Could not parse response data: " + e);
            }
            if (resp.status !== 200) throw new Error(resp.data.message);
            return resp;
        })
        .catch(err => {
            console.warn("Could not load new data: " + err);
            return err;
        })
};

const esc = encodeURIComponent;
const resolveInfo = (table, params, omitCache = false) => new Promise(resolve => {
    if (isUpdated(table) && !omitCache) {
        resolve(defaultsFor(table));
    } else {
        const queryParams = params !== undefined 
            ? "?" + Object.keys(params)
                .map(k => esc(k) + '=' + esc(params[k]))
                .join('&') 
            : "";
        const path = "info/" + table + ".php" + queryParams;

        fetchBackend(path, {
                method: 'GET',
            }
        ).then(({ string, data }) => {
            if (data === undefined || data.data === undefined)
                throw new Error("Unresolved error: could not read json data " + string);
            if (data.message)
                throw data.message.toString();
            saveLastData(path, data.data);
            resolve(data.data);
        }).catch(err => {
            setNotUpdated(path);
            console.warn("Could not retrieve new data: " + err);
            console.warn("Resolve defaults for: " + path);
            resolve(defaultsFor(path));
        })
    }
});

const resolveAdminPassword = () => window.localStorage.getItem("saved-password");

export const BackendService = {
    resolveCarousel: () => resolveInfo("carousel"),
    resolveContacts: () => resolveInfo("contacts"),
    resolvePartners: () => resolveInfo("partners"),
    resolveSocialMedias: () => resolveInfo("socialMedias"),
    resolveArticleWith: (id) => resolveInfo("articles", { id }, false),
    resolveArticleChildren: (parent) => resolveInfo("articles", { parent }, false),

    resolveImage: (img) => url + img,
    sendMessage: (message) => fetchBackend(
        "sendMessages.php", {
            method: 'POST',
            body: JSON.stringify(message)
        }, true
    ),
};

export default BackendService;

